(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/common/assets/javascripts/event-listeners.js') >= 0) return;  svs.modules.push('/modules/oddset/components/common/assets/javascripts/event-listeners.js');
"use strict";


const logger = svs.core.log.getLogger('modules:oddset:components:common:event-listeners');
const {
  account_balance: accountBalance
} = svs.components;
const {
  getGrasrotenMessageForDialogOrToaster
} = svs.sport.sport_common;
const metaDescSelectors = ['meta[name=description]', "meta[property='og:description']", "meta[name='twitter:description']"];
const metaTitleSelectors = ["meta[property='og:title']", "meta[name='twitter:title']"];
const updateBalance = () => {
  accountBalance.get(true, balance => {
    accountBalance.set(balance);
    logger.info('Balance updated after payment');
  });
};
const login = () => {
  logger.info('Login event from shape client, navigating to login page');
  svs.components.customer_login.login();
};
const logout = () => {
  logger.info('Logout event from shape client, navigating to login page');
  svs.components.customer_login.login();
};
const setMetaContent = value => selector => {
  const el = document.head.querySelector(selector);
  if (!el) {
    logger.error("Missing meta tag with selector ".concat(selector));
    return;
  }
  logger.debug("Meta tag with selector ".concat(selector, " updated"));
  el.content = value;
};
const updatePageMetaData = _ref => {
  let {
    title,
    description
  } = _ref;
  if (!title || !description) {
    logger.error('Missing title and/or description on updatePageMetaData call', "Title: ".concat(title), "Description: ".concat(description));
    return;
  }
  document.title = title;
  metaDescSelectors.forEach(setMetaContent(description));
  metaTitleSelectors.forEach(setMetaContent(title));
};
const showGrasrotenDialog = async () => {
  const grasroten = await getGrasrotenMessageForDialogOrToaster();
  if (grasroten.hasSponsorship) {
    var _svs$sport$sport_comm;
    const dialog = await ((_svs$sport$sport_comm = svs.sport.sport_common) === null || _svs$sport$sport_comm === void 0 ? void 0 : _svs$sport$sport_comm.getContextualGrasrotenDialog());
    dialog.options.autoClose = 4000;
    dialog.options.area = svs.components.dialog.area.TOASTER;
    svs.components.dialog.api.add(dialog);
  } else {
    new svs.sport.GrassrootsToaster().show();
  }
};
setGlobal('svs.oddset.components.common.utils', {
  updateBalance,
  login,
  logout,
  updatePageMetaData,
  showGrasrotenDialog
});

 })(window);