(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/oddset/components/common/assets/javascripts/constants.js');
"use strict";


const MenuTabTypes = {
  Play: 'spela',
  MyBets: 'mina-spel',
  GameGuide: 'spelguide',
  Widget: 'widgetyta'
};
const ShapeComponentConfigTypes = {
  SPORTSBOOK: 'sportsbook',
  BET_HISTORY: 'bet-history'
};
const ShapeComponentConfig = {
  [ShapeComponentConfigTypes.SPORTSBOOK]: {
    remote: 'web-embed-sportsbook',
    component: 'Sportsbook',
    divId: 'sportsbook'
  },
  [ShapeComponentConfigTypes.BET_HISTORY]: {
    remote: 'betting.embed-bet-history',
    component: 'BetHistory',
    divId: 'bethistory'
  }
};
const ShapeComponentLoggingMap = {
  [ShapeComponentConfigTypes.SPORTSBOOK]: {
    logToken: 'playerSessionId'
  },
  [ShapeComponentConfigTypes.BET_HISTORY]: {
    logToken: 'providerToken'
  }
};
const widgetAreaPrefix = 'oddsetWidgetArea';
setGlobal('svs.oddset.components.common.constants', {
  MenuTabTypes,
  widgetAreaPrefix,
  ShapeComponentConfig,
  ShapeComponentConfigTypes,
  ShapeComponentLoggingMap
});

 })(window);