(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/common/assets/javascripts/get-time-zone.js') >= 0) return;  svs.modules.push('/modules/oddset/components/common/assets/javascripts/get-time-zone.js');
"use strict";


function getTimeZone() {
  const offset = svs.lbUtils.helperFormatDate.data.timeZoneOffset;
  return "+0".concat(Math.floor(Math.abs(offset) / 60), ":00");
}
setGlobal('svs.oddset.components.common.getTimeZone', getTimeZone);

 })(window);